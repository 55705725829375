import { memo, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Route, Routes, useLocation, useMatch } from "react-router-dom";
import loadable from "@loadable/component";
import SmartDPSearchSummary from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { AsyncBooking } from "app/routes";
import SmartDPBreadcrumb from "app/pages/SmartDP/Booking/SmartDPBreadcrumb/SmartDPBreadcrumb";
import SmartDPHotelDescriptionContainer from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescriptionContainer";
import { QUOTATION_CODE_STATUS } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import SpecialOfferBanner from "app/pages/.shared/SpecialOffer/SpecialOfferBanner/SpecialOfferBanner";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import useHidePartnerCustomHeader from "app/utils/hooks/useHidePartnerCustomHeader";

import "./SmartDP.scss";

export const AsyncSmartDPSearch = loadable(() =>
	import(/* webpackChunkName: "smartdp-search" */ "app/pages/SmartDP/Search/SmartDPSearchContainer")
);

export const AsyncSmartDPListingContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp-listing" */ "app/pages/SmartDP/Listing/SmartDPListingContainer")
);

const SmartDP = ({
	booking = {},
	homeBlocks = [],
	fetchSDPProductsStatus,
	hasSdpSpecialOffer,
	specialOfferInfos = {},
}) => {
	const { pathname } = useLocation();
	const smartDPRef = useRef(null);

	const isSDPQuotePage = pathname === "/sdp/booking/quote";
	const isSdpSearchPage = pathname.includes("sdp/search");

	const isSDPListingPage = pathname.includes("/sdp/listing");

	const isSDPBookingPage = pathname.includes("/sdp/booking");

	const isSDPSearchPage = useMatch({
		path: "/sdp/search",
		end: true,
		caseSensitive: true,
	});
	const isSDPRootPage = useMatch({
		path: "/",
		end: true,
		caseSensitive: true,
	});

	const headerClass = classNames("smartdp", {
		"smartdp--sticky": isSDPListingPage,
		"smartdp--search": isSDPRootPage || isSDPSearchPage,
	});

	useHidePartnerCustomHeader({ hide: !isSdpSearchPage });

	return (
		<div className={headerClass} ref={smartDPRef}>
			<Routes>
				<Route path="/search" element={null} />
				<Route
					path="/hotelDescription/:accommodationId/:code"
					element={
						<HeaderContainer
							showMenuItems
							showDialPhone={false}
							showBackBtn={false}
							disabledHomeRedirect={false}
						/>
					}
				/>
				<Route
					path="/listing/*"
					element={
						<HeaderContainer
							showMenuItems
							showDialPhone={false}
							showBackBtn
							disabledHomeRedirect={false}
						/>
					}
				/>
				<Route
					path="/booking/payment"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone
							showBackBtn
							disabledHomeRedirect={true}
						/>
					}
				/>
				<Route
					path="/booking/quote"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone
							showBackBtn
							disabledHomeRedirect={true}
						/>
					}
				/>
				<Route
					path="/booking/*"
					element={
						<HeaderContainer
							showMenuItems={false}
							showDialPhone={false}
							showBackBtn
							disabledHomeRedirect={false}
						/>
					}
				/>
				<Route path="/" element={null} />
			</Routes>
			<div className="smartdp__subheader">
				{(isSDPQuotePage || isSDPListingPage) && (
					<>
						{fetchSDPProductsStatus === QUOTATION_CODE_STATUS.SUCCESS ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.WARNING ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.BAD_REQUEST ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.FULL_FLIGHT ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.FULL_PROPERTY ? (
							<div className="smartdp__summary">
								<SmartDPSearchSummary
									occupancies={booking?.occupancies}
									departureCityLabel={booking?.departureCity?.label}
									departureResortLabel={booking?.destinationResort?.label}
								/>
							</div>
						) : (
							<div className="smartdp__summary-loader">
								<LoaderBar width={100} height={15} />
								<LoaderBar width={"90%"} height={15} />
								<LoaderBar width={150} height={15} />
							</div>
						)}
					</>
				)}
				{(isSDPQuotePage || isSDPListingPage) && hasSdpSpecialOffer && (
					<SpecialOfferBanner
						background={specialOfferInfos.ui?.background}
						titleColor={specialOfferInfos.ui?.titleColor}
						title={specialOfferInfos.title}
						pictoUrl={specialOfferInfos.ui?.pictoUrl}
					/>
				)}
			</div>
			{isSDPBookingPage && (
				<div className="smartdp__breadcrumb">
					<SmartDPBreadcrumb />
				</div>
			)}
			<Routes>
				<Route path="/search" element={<AsyncSmartDPSearch homeBlocks={homeBlocks} />} />
				<Route
					path="/hotelDescription/:accommodationId/:code"
					element={<SmartDPHotelDescriptionContainer printMode={true} />}
				/>
				<Route path="/listing/*" element={<AsyncSmartDPListingContainer />} />
				<Route path="/booking/*" element={<AsyncBooking />} />
				<Route
					path="/"
					element={<AsyncSmartDPSearch homeBlocks={homeBlocks} smartDPRef={smartDPRef} />}
				/>
			</Routes>
			<PushNotificationContainer />
		</div>
	);
};

SmartDP.propTypes = {
	booking: PropTypes.object,
	homeBlocks: PropTypes.array,
	fetchSDPProductsStatus: PropTypes.string,
	hasSdpSpecialOffer: PropTypes.bool,
	specialOfferInfos: PropTypes.object,
};

export default memo(SmartDP);
