import { memo } from "react";
import BreadcrumbItem from "app/pages/Breadcrumb/BreadcrumbItem";
import { FormattedMessage } from "react-intl";
import Breadcrumb from "app/pages/Breadcrumb/Breadcrumb";
import { useMatch } from "react-router-dom";
import "./SmartDPBreadcrumb.scss";

const SmartDPBreadcrumb = () => {
	const isSDPQuotationPage = useMatch({
		path: "/sdp/booking/quote",
		caseSensitive: true,
	});

	const isSDPPaiementPage = useMatch({
		path: "/sdp/booking/payment",
		caseSensitive: true,
	});

	const isSDPConfirmationPage = useMatch({
		path: "/sdp/booking/confirmation",
		caseSensitive: true,
	});

	return (
		<div className="sdp-breadcrumb">
			<Breadcrumb>
				<BreadcrumbItem
					isActive={Boolean(isSDPQuotationPage)}
					isPassed={Boolean(isSDPPaiementPage) || Boolean(isSDPConfirmationPage)}
					label={<FormattedMessage id="sdp.booking.breadcrumb.step.1" />}
					disableClick={true}
				/>
				<BreadcrumbItem
					isActive={Boolean(isSDPPaiementPage)}
					isDisabled={Boolean(isSDPQuotationPage)}
					isPassed={Boolean(isSDPConfirmationPage)}
					label={<FormattedMessage id="sdp.booking.breadcrumb.step.2" />}
					disableClick={true}
				/>
				<BreadcrumbItem
					isActive={Boolean(isSDPConfirmationPage)}
					isDisabled={Boolean(isSDPQuotationPage) || Boolean(isSDPPaiementPage)}
					label={<FormattedMessage id="sdp.booking.breadcrumb.step.3" />}
					disableClick={true}
				/>
			</Breadcrumb>
		</div>
	);
};

export default memo(SmartDPBreadcrumb);
