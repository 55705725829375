import { connect } from "react-redux";
import { getPartnerMarketingHomeBlocks } from "app/reducers/partnerSelector";
import { useEffect } from "react";
import PropTypes from "prop-types";
import SmartDP from "app/pages/SmartDP/SmartDP";
import { bindActionCreators } from "redux";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";

const SmartDpContainer = props => {
	const { fetchSDPFilters } = props;

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	return <SmartDP {...props} />;
};

SmartDpContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
};

const mapStateToProps = state => {
	const currentMerchCode = state.merchandising?.current?.code;
	const booking = currentMerchCode ? state.booking.smartDPMerch : state.booking;

	return {
		booking,
		homeBlocks: getPartnerMarketingHomeBlocks(state),
		fetchSDPProductsStatus: state.smartDP.status,
		hasSdpSpecialOffer: state.smartDP.specialOffer, // true si le back renvoi au moins un vol remboursable
		specialOfferInfos: state.smartDP.specialOfferInfos,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDpContainer);
